import React, { useEffect, useState, useRef } from 'react';

import Papa from "papaparse"

import axios from "axios"

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import UPCModal from '../subViews/UPCModal';
import UPCCreateModal from '../subViews/UPCCreateModal';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



export default function UPC() {
    const [input, setInput] = useState("940412")
    const [upc, setUpc] = useState("")
    const [cd, setCd] = useState()
    // const [uploadData, setUploadData] = useState([])
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('');


    //
    const [createOpen, setCreateOpen] = useState(false);
    const [createLoad, setCreateLoad] = useState(0);
    const [failData, setFailData] = useState([]);
    const [data, setData] = useState([]);


    //REWORK START
    const handleLoadTrue = () => setCreateLoad(1);
    const handleLoadFalse = () => setCreateLoad(0);


    //REF

    const handleCreateOpen = () => setCreateOpen(true);

    const handleCreateClose = () => {
        setCreateOpen(false)
        handleLoadFalse(0)
        setData([])
        setFailData([])
    }
    const fileInputRef = useRef(null);



    const handleFileUpload = (e) => {
        let currentTime = new Date().toLocaleString()
        console.log(currentTime)
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {

      
           
      
                return (
                  {
                    sku: elem.sku,
                
                  
                
                  }
                )
                
          
              })
              console.log(formArray)

            //   setUploadData(formArray)
            handleAssignmentCheck(formArray)

              handleCreateOpen()

              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
    
           
            }}
          )
        }
      
      }

      const handleAssignmentCheck = async (uploadData) => {

        const assignedUPCs = await axios.post("/api/upc/getassigned")
        console.log(assignedUPCs)
    
        const matchedData = uploadData.map((elem) => {
            const foundData = assignedUPCs.data.find((ellm) => ellm.sku === elem.sku)
    
            return (
                {
                    sku: elem.sku,
                    upc: (foundData ? foundData.upc : "")
                }
            )
    
    
        })
    
        console.log(matchedData)
    
        const noMatch = matchedData.filter((elem) => elem.upc === "")
        const hasMatch = matchedData.filter((elem) => elem.upc !== "")
        handleLoadTrue()
    
        if (noMatch.length > 0) {
    
        setFailData(noMatch)
        setData(hasMatch)
        
    
        } else {
    
            // generateExcelFile(matchedData)
            setData(hasMatch)
    
        }
    
        
        
        }






    //REWORK END


    const numberGen = () => {

        let prefix = "940412"

        const lowerBound = 11028
        const upperBound = 12000

        let buffArray = []


        for (let i = lowerBound; i < upperBound; i++) {

            buffArray.push(i.toString())
        }


        let upcArray = buffArray.map((elem) => {

            let concatUPC = prefix + elem

            let testArray = concatUPC.split("")

            let i = 1
            let sum = 0
    
        
    
            testArray.forEach((elem) => {
                let num = parseInt(elem)
    
                if (i % 2 === 0) {
                    sum += num
                } else {
                    sum += (num * 3)
                }
    
                i++
    
                
            })
    
            let checkDigit = (Math.ceil(sum/10) * 10) - sum
    
            let logValue = concatUPC + checkDigit



            return (
                {
                    upc: logValue,
                    sku: ""
                }
            )
        })

        console.log(upcArray)

        handleUPCUpload(upcArray)






    }

    const handleGen = () => {

        let suffix = 11027

        console.log(suffix)


        let testValue = input + suffix.toString()

      

        let testArray = testValue.split("")

        let i = 1
        let sum = 0

    

        testArray.forEach((elem) => {
            let num = parseInt(elem)

            if (i % 2 === 0) {
                sum += num
            } else {
                sum += (num * 3)
            }

            i++

            
        })

        let checkDigit = (Math.ceil(sum/10) * 10) - sum

        let logValue = testValue + checkDigit

        setUpc(logValue)
        setCd(checkDigit)


        console.log(testValue)
        console.log(checkDigit)




    }


    const getTableData = async () => {
        let response = await axios.post("/api/upc/getunassigned")

        console.log(response.data)

      
    }


    const handleUPCUpload = async (data) => {

        for (const elem of data) {
            let response = await axios.post("/api/upc/upload", 
            {
              sku: elem.sku,
              upc: elem.upc
            } 
            )

            console.log(response)

        }

      



    }

    //TABLE FUNCTIONS
    const columns = tableData.length > 0 ? Object.keys(tableData[0]) : [];


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };




    const sortedRowData = tableData.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
            return orderDirection === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return orderDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });



    const currentRows = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    return (
        <div style={{ marginLeft: "17vw", display:"flex" }}>
            {/* <h1>TEST</h1>    
            <input onChange={(e) => setInput(e.target.value)}></input>
            <button onClick={handleGen}>Gen</button>
            <br></br>
            <input onChange={handleFileUpload} type="file" accept=".csv"></input>
            <button onClick={handleUPCUpload}>UPLOAD</button>
            <button onClick={numberGen}>Number Gen</button>
            <button onClick={getTableData}>Table Data</button> */}
  <div style={{margin:"10vw"}}>
        <Button style={{height:"10vh", width:"10vw"}} component="label" variant="contained" >
        Assign
        <VisuallyHiddenInput ref={fileInputRef} onChange={handleFileUpload} type="file" accept=".csv" />
        </Button>
        </div>
        <div style={{margin:"10vw"}}>
            <UPCModal />
        </div>
            <UPCCreateModal createOpen={createOpen} handleCreateClose={handleCreateClose} handleCreateOpen={handleCreateOpen} createLoad={createLoad} data={data} failData={failData} />

        </div>
    )
}