
import React, { useState, useEffect }  from "react"

//MUI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import axios from "axios"


//Charts
import {
    AnimatedAxis, // any of these can be non-animated equivalents
    AnimatedGrid,
    AnimatedLineSeries,
    XYChart,
    Tooltip,
  } from '@visx/xychart';








export default function Dashboard() {
    const [data1, setData1] = useState([])
    const [ordersCount, setOrdersCount] = useState("")
    const [sales, setSales] = useState("")
    const [aov, setAov] = useState("")
    const [topItem, setTopItem] = useState("")
    const [shopStatus, setShopStatus] = useState(false)
    const [mongoStatus, setMongoStatus] = useState(false)


   
      
      
    
      const accessors = {
        xAccessor: (d) => d.x,
        yAccessor: (d) => d.y,
      };


    const getData = async () => {
        let response = await axios.post("/api/shopql/getnetsales")
    
    
    
        console.log(response.data.data.shopifyqlQuery.tableData.rowData)

        const formResponse = response.data.data.shopifyqlQuery.tableData.rowData
    
    
        let data = formResponse.slice(0, (formResponse.length - 1 ))
    
    
            let formattedData = data.map((elem) => {

                const cleanedString = elem[1].replace(/[$,]/g, '');

                let number = parseFloat(cleanedString)
    
                return (
                    {
                        x: elem[0], y:number
                    }
    
                )
            })
    
            console.log(formattedData)


            setData1(formattedData)
    
    
      }


      const getOrderData = async () => {

        let response = await axios.post("/api/shopify/getordersiso")

        setShopStatus(true)


        console.log(response.data)


        let lineItems = response.data.map((elem) => {

            return (
                elem.line_items
            )
        }).flat()

        let reducedLineItems = lineItems.reduce((prev, next) => {

            if (prev[next.title]) {
                prev[next.title]++;
            } else {

                prev[next.title] = 1;
            }
            return prev;
        }, {});


        console.log(reducedLineItems)

        const getKey = (obj) =>  {
            let highestKey = null;
            let highestValue = -Infinity;
        
            for (let key in obj) {
                if (obj[key] > highestValue) {
                    highestValue = obj[key];
                    highestKey = key;
                }
            }
        
            return highestKey;
        }


       

        

        setTopItem(getKey(reducedLineItems))

       


        let cTP = response.data.reduce((prev, next) => {
            return Number(prev) + Number(next.current_total_price);
        }, 0);

        console.log(cTP)

        const formattedCTP = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(cTP);
        

        console.log(formattedCTP)

        let unAOV = cTP / response.data.length


        const formattedAOV = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(unAOV);


        console.log(formattedAOV)


        setOrdersCount(response.data.length.toString())
        setSales(formattedCTP)
        setAov(formattedAOV)


        


      }


      const checkMongo = async () => {
        let response = await axios.post("/api/report/getreport")

        setMongoStatus(true)
      }


      useEffect(() => {
        getOrderData()
        getData()
        checkMongo()

      }, [])





    return (
        <>

            <div style={{ display: "flex" }}>
                
                <Card style={{ backgroundColor: 'var(--content-color)', width: "19vw", height:"12vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "5vh", marginTop: "5vh", boxShadow: "0px 0px 20px -3px var(--primary-color)"  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                    Today's Orders
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div">
                        {ordersCount !== "" ?
                        ordersCount 
                        :
                        <CircularProgress color="inherit" />
                        }
               
                    </Typography>
                </CardContent>
                </Card>

                <Card style={{ backgroundColor: 'var(--content-color)', width: "19vw", height:"12vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "5vh", marginTop: "5vh", boxShadow: "0px 0px 20px -3px var(--primary-color)"  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                    Today's Sales
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div">
                    {sales !== "" ?
                        sales
                        :
                        <CircularProgress color="inherit" />
                        }
                    </Typography>
                </CardContent>
                </Card>


                <Card style={{ backgroundColor: 'var(--content-color)', width: "19vw", height:"12vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "5vh", marginTop: "5vh", boxShadow: "0px 0px 20px -3px var(--primary-color)"  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                    Today's AOV
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div">
                    {aov !== "" ?
                        aov
                        :
                        <CircularProgress color="inherit" />
                        }
                    </Typography>
                </CardContent>
                </Card>

                <Card style={{ backgroundColor: 'var(--content-color)', width: "20vw", height:"12vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "5vh", marginTop: "5vh", boxShadow: "0px 0px 20px -3px var(--primary-color)"  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                    Today's Top Product
                    </Typography>
                    <Typography variant="9" color="#ededf2" component="div">
                    {topItem !== "" ?
                        topItem
                        :
                        <CircularProgress color="inherit" />
                        }
                    
                    </Typography>
                </CardContent>
                </Card>


            </div>
            <div style={{ display: "flex" }}>
            <Card style={{ backgroundColor: 'var(--content-color)', width: "19vw", height:"45vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "5vh", marginTop: "1vh", boxShadow: "0px 0px 20px -3px var(--primary-color)"  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                        Status
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        Shopify
                        { shopStatus ?
                            <CheckCircleOutlineIcon color="success" />
                        :
                            <CircularProgress color="inherit" size={24} />
                        }
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        Firebase
                        <CheckCircleOutlineIcon color="success" />
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        MongoDB
                        { mongoStatus ?
                            <CheckCircleOutlineIcon color="success" />
                        :
                            <CircularProgress color="inherit" size={24} />
                        }
                    </Typography>
                </CardContent>
            </Card>

                <Card style={{ backgroundColor: 'var(--content-color)', width: "62vw", height:"45vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "3vh", marginTop: "1vh", boxShadow: "0px 0px 20px -3px var(--primary-color)"  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                    Monthly Sales
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div">
                        {data1.length > 0 ?
                    <XYChart height={300} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>
                        <AnimatedAxis 
                        orientation="bottom"
                        tickValues={data1.filter((d, i) => i % 7 === 0).map(accessors.xAccessor)}
                        tickLabelProps={() => ({
                            fill: 'white', 
                            fontSize: 12,
                            textAnchor: 'middle',
                        })}
                         />
                        <AnimatedGrid columns={false} numTicks={4} />
                        <AnimatedLineSeries dataKey="Sales" data={data1} {...accessors} />
                    
                        <Tooltip
                        snapTooltipToDatumX
                        snapTooltipToDatumY
                        showVerticalCrosshair
                        showSeriesGlyphs
                        renderTooltip={({ tooltipData, colorScale }) => (
                            <div>
                            <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
                                {tooltipData.nearestDatum.key}
                            </div>
                            {accessors.xAccessor(tooltipData.nearestDatum.datum)}
                            {', '}
                            {accessors.yAccessor(tooltipData.nearestDatum.datum)}
                            </div>
                        )}
                        />
                    </XYChart>
                    :
                    <CircularProgress color="inherit" />

}
                    </Typography>
                </CardContent>
                </Card>

                {/* <Card style={{ backgroundColor: 'var(--content-color)', width: "17vw", height:"45vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "5vh", marginTop: "5vh", boxShadow: "0px 0px 10px -3px" }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                   
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div">
               
                    </Typography>
                </CardContent>
                </Card> */}
         

            </div>
            </>
        
 

    )
}