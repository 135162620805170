import React, { useEffect, useState } from 'react';

import Papa from "papaparse"

import axios from "axios"

import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import loading from "../assets/loadingNew.gif"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    height: "80vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export default function UPCModal() {
    const [open, setOpen] = useState(false);
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [searchValue2, setSearchValue2] = useState('');


    const handleChange = (e) => {
        setSearchValue2("")
        setSearchValue(e.target.value)
    }

    const handleChange2 = (e) => {
        setSearchValue("")
        setSearchValue2(e.target.value)
    }


    const handleOpen = () => {

        setOpen(true);
        getTableData()
    };

    const handleClose = () => setOpen(false);


    const getTableData = async () => {
        let response = await axios.post("/api/upc/getassigned")

        console.log(response.data)

        let dataBuff = response.data.map((elem) => {
            return (
                {
                    sku: elem.sku,
                    upc: elem.upc,
                    updated_by: elem.updated_by,
                    updated_at: elem.updated_at
                }
            )
        })

        setTableData(dataBuff)
    }

    const generateExcelFile = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
    
        const data = tableData
    
      
        // Add column headers
        const headers = Object.keys(data[0]);
        worksheet.addRow(headers);
    
        // Add data rows
        data.forEach(item => {
          const values = Object.values(item);
          worksheet.addRow(values);
        });
    
    
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'upcList.xlsx');
        });
      };



      //TABLE FUNCTIONS
      const columns = tableData.length > 0 ? Object.keys(tableData[0]) : [];


      const handleRequestSort = (property) => {
          const isAsc = orderBy === property && orderDirection === 'asc';
          setOrderDirection(isAsc ? 'desc' : 'asc');
          setOrderBy(property);
      };
  
      const handleChangePage = (event, newPage) => {
          setPage(newPage);
      };
  
      const handleChangeRowsPerPage = event => {
          setRowsPerPage(+event.target.value);
          setPage(0);
      };

      const filteredData = tableData.filter(row =>
        row.sku && row.sku.toLowerCase().includes(searchValue.toLowerCase())
    )


    const filteredData2 = filteredData.filter(row =>
        row.upc && row.upc.toLowerCase().includes(searchValue2.toLowerCase())
    )
  
  
  
  
      const sortedRowData = filteredData2.sort((a, b) => {
          if (a[orderBy] < b[orderBy]) {
              return orderDirection === 'asc' ? -1 : 1;
          }
          if (a[orderBy] > b[orderBy]) {
              return orderDirection === 'asc' ? 1 : -1;
          }
          return 0;
      });
  
  
  
      const currentRows = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


      return (
        <div style={{  }}>
            <Button style={{height:"10vh", width:"10vw"}} component="label" variant="contained" onClick={handleOpen}>View All</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

            <Box sx={style}>
                    {tableData.length > 0 ? (
                        <>
                        <div>
                        <input placeholder='Filter by SKU' value={searchValue} onChange={handleChange}></input>
                        <input placeholder='Filter by UPC' value={searchValue2} onChange={handleChange2}></input>
                        <button onClick={generateExcelFile}>Export</button>
                        </div>
                      
                            <TableContainer component={Paper} style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(column => (
                                                <TableCell key={column}>
                                                    <TableSortLabel
                                                        active={orderBy === column}
                                                        direction={orderBy === column ? orderDirection : 'asc'}
                                                        onClick={() => handleRequestSort(column)}
                                                    >
                                                        {column}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentRows.map((row, index) => (
                                            <TableRow key={index}>
                                                {columns.map(column => (
                                                    <TableCell key={column}>{row[column]}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    ) : (
                        <Box sx={style}>

                        <img style={{margin:"10vw", height:"40vh", marginLeft:"25vw"}}src={loading} />
        
                        </Box>
                    )}
                </Box>
                </Modal>

        </div>
    )




}