
//DEPS
import React, { useEffect, useState } from 'react';
import axios from "axios"

//COMPONENTS
import PackDisplay from '../subViews/PackDisplay';
import place from "../assets/placeholderPACAS.png"


export default function PackCreator(props) {
    const [variantData, setVariantData] = useState([])


    const mergePrimary = (a,b,c) => {
      //
      console.log(c)
      let combinedData = a.map(elem => {
        // console.log(elem)
        let cMap = elem.children.map(ellm => {

          // console.log(ellm)
            
        
        let findImage = c.find(elm => elm.id === ellm.image_id)

        let image = ""

        if (findImage && findImage.src !== "") {image = findImage.src} else {
          image = place
          // console.log("No Image")
        }
        return ({
          sku: ellm.sku,
          image: image,
          color: ellm.option1,
          size: ellm.option2,
          productId: ellm.product_id,
          stock: ellm.inventory_quantity,
          id: ellm.id,
        })

      })

      // console.log(cMap)

        return ({
          sku: elem.sku,
          image_id: elem.image_id,
          option1: elem.option1,
          option2: elem.option2,
          product_id: elem.product_id,
          inventory_quantity: elem.inventory_quantity,
          id: elem.id,
          children: cMap

        })
    })



    // console.log(combinedData)
      //
      getMulti(combinedData,b)
    }


    const mergeData = (a,b,c,d) => {
      console.log(a)
        let combinedData = a.map(elem => {
            let findImage = b.find(elm => elm.id === elem.image_id)
            let image = ""
            if (findImage) {image = findImage.src} else {
              image = place
              console.log("No Image")
            }

            let findABV = c.find(elm => elm.sku === elem.sku)
            let ABV = 0
            if (findABV) {ABV = findABV.quant}

            let findAPV = d.find(elm => elm.sku === elem.sku)
            let APV = 0
            if (findAPV) {APV = findAPV.quant}

            //STYLE ALIAS
            let style = elem.product_id
            let cat = elem.product_id
            if (elem.product_id === 6852663804004 || elem.product_id === 6620534669412 || elem.product_id === 6597571575908 || elem.product_id === 4837716951140 || elem.product_id === 6950074384484 || elem.product_id === 7163184283748 || elem.product_id === 4837733957732 || elem.product_id === 7286790619236 || elem.product_id === 7851959943268 || elem.product_id === 7901240098916 ) { 
              style = "Crew Socks"
              cat = "Women's"
            } 
            
            else if (elem.product_id === 6873293586532 || elem.product_id === 6930797101156 || elem.product_id === 7255628546148 || elem.product_id === 7217697390692 || elem.product_id === 7902016798820) {
              style = "Compression Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6824475361380) {
              style = "Golf Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6609129275492 || elem.product_id === 7255634051172 || elem.product_id === 7217910743140 || elem.product_id === 6609176789092 || elem.product_id === 6609178230884 || elem.product_id === 7863713726564 ) {
              style = "Light-Weight Crew Socks"
              cat = "Women's"
            }
            else if ( elem.product_id === 6626100314212 || elem.product_id === 6816252428388 || elem.product_id === 4823040131172 || elem.product_id === 7217900060772 || elem.product_id === 7851605065828 ) {
              style = "Low-Cut Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6541486489700 || elem.product_id === 7321956876388 || elem.product_id === 7909757681764 || elem.product_id === 6585673187428) {
              style = "No-Show Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6557907746916 || elem.product_id === 6558139154532) {
              style = "Performance Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 6575734030436 || elem.product_id === 6575736914020) {
              style = "Performance Socks"
              cat = "Men's"
            }
            else if (elem.product_id === 7105370357860 || elem.product_id === 7105422360676) {
                style = "Slouchy Socks"
                cat = "Women's"
              }
            else if (elem.product_id === 7194189430884) {
              style = "Hiking Crew Socks"
              cat = "Women's"
            }
            else if (elem.product_id === 7194189987940) {
              style = "Hiking Quarter Socks"
              cat = "Women's"
            }

           
            else if (elem.product_id === 6888734425188 || elem.product_id === 6888748580964 || elem.product_id === 6888738160740 || elem.product_id === 6888742060132 || elem.product_id === 6888726921316 || elem.productId === 6888747335780) {
              style = "Pacas Throw Blanket"
            }
        
            else if (elem.product_id === 2236767502436 || elem.product_id === 6626100314212 || elem.product_id === 6816252428388 || elem.product_id === 6958959296612 || elem.product_id === 7255625433188 ) {
              style = "Low-Cut Socks"
              cat = "Women's"
            }
        
            else if (elem.product_id=== 6956831965284 || elem.product_id === 4837718753380 || elem.product_id === 4837737365604 || elem.product_id === 7223568990308 || elem.product_id === 7255597383780 || elem.product_id === 7851670143076) {
              style = "Crew Socks"
              cat = "Men's"
            }
            else if (elem.product_id === 6878354341988 || elem.product_id === 6930798379108) {
              style = "Compression Socks"
              cat = "Men's"
            }
        
            else if (elem.product_id === 6602920394852 || elem.product_id === 6602924785764 || elem.product_id === 6602925637732 || elem.product_id === 7897203343460) {
              style = "Light-Weight Crew Socks"
              cat = "Men's"
            }

            else if (elem.product_id === 6824474771556) {
              style = "Golf Socks"
              cat = "Men's"
            }

            else if (elem.product_id === 7194190217316) {
              style = "Hiking Crew Socks"
              cat = "Men's"
            }

            else if (elem.product_id === 7194190512228) {
              style = "Hiking Quarter Socks"
              cat = "Men's"
            }
            else if (elem.product_id === 4838251888740 || elem.product_id === 4838251987044 || elem.product_id === 7912946368612) {
              style = "Crew Socks"
              cat = "Kids'"
            }
        
            else if (elem.productId === 6873185353828 || elem.productId === 6862851080292 || elem.productId === 6873188270180 || elem.productId === 6873186828388 || elem.productId === 6873182437476 ) {
              style = "Pacas Summer Wrap"
            }
        
            else if (elem.product_id === 6972996714596 || elem.product_id === 6972996157540 || elem.product_id === 6972996976740 || elem.product_id === 6972996419684 || elem.product_id === 6972997271652 ) {
              style = "Pacas Shawl"
            }
        
            else if (elem.product_id === 4838368051300 || elem.product_id === 4838370410596 ) {
              style = "Toddler Pacas Socks"
            }
            else if (elem.product_id === 7000945000548 || elem.product_id === 7000945590372  || elem.productId === 7000423956580) {
              style = " Pacas Scarf"
            }
        
            else if (elem.product_id === 7082909958244 || elem.product_id === 7082909139044 ) {
              style = "Gift Box"
            }
    

            else if (elem.product_id === 4823046324324 || elem.product_id === 7255624745060 || elem.product_id === 7851616370788 || elem.product_id === 4823047143524  ) {
                style = "Low-Cut Socks"
                cat = "Men's"
              }
              let setChildren = []

              if (props.view === 1) {

                setChildren = [{sku:elem.sku, image: image, color: elem.option1, size: elem.option2, style: style}]

              } else {

               setChildren = elem.children
              }


            return ({
                sku: elem.sku,
                image: image,
                color: elem.option1,
                size: elem.option2,
                productId: elem.product_id,
                abv: ABV,
                apv: APV,
                stock: elem.inventory_quantity,
                id: elem.id,
                style: style,
                cat: cat,
                children: setChildren

            })
        })

        setVariantData(combinedData)
        console.log(combinedData)

    }

    const getPrimaries = (data, images) => {

      axios.post("/api/shopify/getallprimary", {

      })
      .then((res) => {
        

          let allImages = res.data.products.map(elem => {
              return (
                  elem.images
              )
          })
          let allVariants = res.data.products.map(elem => {
              return (
                  elem.variants
              )
          })

          //DECODE CHANGE

          let allSku = allVariants.flat().map(elem => {
            let split = ""
            if (elem.sku.includes("X")) {
              split = elem.sku.split("X")
            } else {
             split = elem.sku.split("-")
            }
            let splitForm = []
            if (elem.sku.includes("X")) {
             splitForm = split.map(elem => {
              return (
                elem.trim().substring(2)
              )
            })
          } else {
            splitForm = split.map(elem => {
              return (
                elem
              )
            })
          }
            return (
              splitForm
            )
          })

          let filterDups = allSku.flat().filter((value, index) => allSku.flat().indexOf(value) === index);
          console.log(filterDups)

          const filteredArray = data.filter(obj => filterDups.includes(obj.sku));

          // console.log(filteredArray)

          // console.log(allVariants.flat())

          let childrenArray = allVariants.flat().map(elem => {
            let split = ""
            if (elem.sku.includes("X")) {
              split = elem.sku.split("X")

            }
            else if (elem.sku[1] === "-") {
              split = elem.sku.split(",")
            }
            
            else {
             split = elem.sku.split("-")
            }
            let splitForm = []
            if (elem.sku.includes("X") || elem.sku[1] === "-") {
            
             splitForm = split.map(elem => {
              return (
                {sku: elem.trim().substring(2), quant: parseInt(elem) }
              )
            })
          } else {
             splitForm = split.map(elem => {
              return (
                {sku: elem, quant: 1 }
              )
            })
            console.log(splitForm)
          }

            let quantForm = []

            splitForm.forEach(elem => {
              for (let i = 0; i < elem.quant; i++) {
                quantForm.push({sku: elem.sku})
              }
            })


            // console.log(quantForm)



            let testMatch = quantForm.map(elem => {

           
            let matchArray = filteredArray.find(elm => elm.sku === elem.sku)
            // console.log(splitForm)
            // console.log(matchArray)
            if (matchArray !== undefined) {
            return(
              matchArray
            )
            } else {
              return (
                [{}]
              )
            }
            
          })

          // console.log(testMatch)

          return (
            {
              sku: elem.sku,
              image_id: elem.image_id,
              option1: elem.option1,
              option2: elem.option2,
              product_id: elem.product_id,
              inventory_quantity: elem.inventory_quantity,
              id: elem.id,
              children: testMatch
            }
          )

          })
          console.log(childrenArray)

          mergePrimary(childrenArray,allImages.flat(), images)

          

        })

    }


    const getSingles = () => {
      let query = ""
      
      query = "/api/shopify/getallsingles"
 
        axios.post("/api/shopify/getallsingles", {

        })
        .then((res) => {
          

            let allImages = res.data.products.map(elem => {
                return (
                    elem.images
                )
            })
            let allVariants = res.data.products.map(elem => {
                return (
                    elem.variants
                )
            })



            if (props.view === 1 ) {

            getMulti(allVariants.flat(),allImages.flat())

          } else {
            getPrimaries(allVariants.flat(),allImages.flat() )
          }
            
        })

    }

    const getMulti = (a,b) => {
        axios.post("/api/shopify/getallmulti", {

        })
        .then((res) => {
            console.log(res.data.products)

            let variantMerge = res.data.products.map(elem => {
                return (
                    elem.variants
                )
            })

            // console.log(variantMerge.flat())

            let inStock = variantMerge.flat().filter(elem => elem.inventory_quantity > 0)

            // console.log(inStock)

            let inStockSku = inStock.map(elem => {
              let split = ""
              if (elem.sku.includes("X")) {
                split = elem.sku.split("X")
              } else if (elem.sku[1] === 1){
               split = elem.sku.split(",")
              } else {
                split = elem.sku.split("-")
                console.log(split)
              }
                return (
                    split.flat()
                )
            })

            // console.log(inStockSku.flat())

            let regex = inStockSku.flat().map(elem => {
                let parts = elem.replace(/\s/g,'')
                return parts
            })

          



            let preReduceABV = regex.map(elem => {
              if (elem[1] === "-") {
                return (
                    {sku: elem.substr(2), quant: parseInt(elem)}
                )
                } else {
                  return (
                    {sku: elem, quant: 1}
                  )
                }
            })

            let preReduceAPV = regex.map(elem => {
              if (elem[1] === "-") {
                return (
                    {sku: elem.substr(2), quant: 1}
                ) 
              } else {
                return (
                  {sku: elem, quant: 1}
                )
              }
            })

            // console.log(preReduce)

            let abv = preReduceABV.reduce((prev, curr) => {
                let count = prev.get(curr.sku) || 0;
                prev.set(curr.sku, curr.quant + count);
                return prev;
              }, new Map());
              
              abv = [...abv].map(([sku, quant]) => {
                return {sku, quant};
              });

              console.log(abv)


            let apv = preReduceAPV.reduce((prev, curr) => {
                let count = prev.get(curr.sku) || 0;
                prev.set(curr.sku, curr.quant + count);
                return prev;
              }, new Map());
              
              apv = [...apv].map(([sku, quant]) => {
                return {sku, quant};
              });

              console.log(apv)

              mergeData(a,b,abv,apv)
        })
    }



    //TESTING
    const logData = () => {
        console.log(variantData)
    }

    useEffect(() => {
        getSingles()
       
       
    }, [])


    return (
        <div style={{ marginLeft: "200px" }}>
            {/* <h1>Pack Creator</h1> */}
            {/* <button onClick={logData}>Log Data</button> */}
            
            <PackDisplay variantData={variantData} changeView={props.changeView} skuChecked={props.skuChecked} view={props.view} />

        </div>
    )
}