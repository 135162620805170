import React, { useEffect, useState } from 'react';

import Papa from "papaparse"

import axios from "axios"

import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import Modal from '@mui/material/Modal';
import loading from "../assets/loadingNew.gif"
import Button from '@mui/material/Button';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export default function UPCCreateModal( {createOpen, createLoad, handleCreateClose, data, failData} ) {
    const [open, setOpen] = useState(false);
    const [uploadData, setUploadData] = useState(false);
    const [toAssign, setToAssign] = useState([])


    const handleOpen = () => {

        setOpen(true);
       
    };

    const handleClose = () => setOpen(false);


    const handleFileUpload = (e) => {
        let currentTime = new Date().toLocaleString()
        console.log(currentTime)
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {

      
           
      
                return (
                  {
                    sku: elem.sku,

                  }
                )
                
          
              })
              console.log(formArray)

              setUploadData(formArray)
    
           
            }}
          )
        }
      
      }



const handleAssignment = async () => {

let count = failData.length

    let response = await axios.post("/api/upc/getunassigned", {
        count: count
    })

    console.log(response)


    let combinedData = failData.map((elem, i) => {
        return (
            {
                upc: response.data[i].upc,
                sku: elem.sku,
                user: "Jacob@pacas.com"

            }
        )
    })


    console.log(combinedData)

  
    if (combinedData.length > 0) {

        for (const elem of combinedData) {
            let response = await axios.post("/api/upc/assign", {
                upc: elem.upc,
                sku: elem.sku,
                user: elem.user
            })

            console.log(response)
        }


    }


    const assignedDataForm = combinedData.map((elem) => {
        return (
            {
                sku:elem.sku,
                upc:elem.upc
            }
        )
    })

    const joinData = data.concat(assignedDataForm)

    console.log(data)


    console.log(joinData)

    generateExcelFile(joinData)

    handleCreateClose()



}


const generateExcelFile = async (upcData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const data = upcData

  
    // Add column headers
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);

    // Add data rows
    data.forEach(item => {
      const values = Object.values(item);
      worksheet.addRow(values);
    });


    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'upcList.xlsx');
    });
  };





let headers = []

if (failData.length > 0) {
    headers = Object.keys(failData[0])
} 
else if (failData.length === 0 && data.length > 0 ) {
    headers = Object.keys(data[0])
}



const AssignTable = () => (
    <>
  
    <table className="data-table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
         
        </tr>
      </thead>
      <tbody>
        {failData.map((row, index) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={header}>{row[header]}</td>
            ))}
       
          </tr>
        ))}
      </tbody>
    </table>
    </>
  );


  const UPCTable = () => (
    <>
    
    <table className="data-table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
         
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={header}>{row[header]}</td>
            ))}
       
          </tr>
        ))}
      </tbody>
    </table>
    </>
  );


  const handleExport = () => {
    generateExcelFile(data)
  }




      return (
        <div style={{ marginLeft: "17vw" }}>
            {/* <button onClick={handleOpen}>Assignment</button> */}
            <Modal
                open={createOpen}
                onClose={handleCreateClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"



            >
                {(createLoad === 0)
                ?

                <Box sx={style}>

                <img style={{margin:"10vw", height:"40vh", marginLeft:"25vw"}}src={loading} />

                </Box>
                :
                



            (failData.length > 0)
                ?
                <Box sx={style} >
                    <h3>Some UPC's not assigned</h3>
                 <div style={{height:"60vh",overflowY: "scroll",marginBottom: "80px"}}>
                 <AssignTable />
                    </div>
               

               
                <button onClick={handleAssignment}>ASSIGN</button>
                  
                </Box>


                :

                <Box sx={style} >
                    <h3>All UPCs already assigned</h3>
                    
                    <div style={{height:"60vh",overflowY: "scroll",marginBottom: "80px"}}>
                        <UPCTable  />
                    </div>

               
                <Button onClick={handleExport}>Export</Button>
                  
                </Box>
            
            
            }

          
                </Modal>

        </div>
    )




}