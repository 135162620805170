
import React, { useEffect, useState } from 'react';
import axios from "axios"
import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';




export default function Alerts() {
    const [user, setUser] = useState("");
    const [load, setLoad] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [hasChanged, setHasChanged] = useState(0)


    const auth = getAuth();

    useEffect(() => {
     
        onAuthStateChanged(auth, (user) => {

          if (user) {
            console.log(user.email)

           
     
            setUser(user.email)
            getData(user.email)
          } else {
            console.log("no")
          }
        });
   
      }, [hasChanged]);


const getData = async (userP) => {
    let response = await axios.post("/api/report/getreport")

    console.log(response.data)



    const subs = response.data.map((elem) => {
        console.log(elem)
        if (elem.subscribers.includes(userP)) {
            return (
             elem.id
            )
            
        }
    }).filter((ellm) => ellm !== undefined)

    setSubscriptions(subs)
    console.log(subs)

    setReportData(response.data)

    setLoad(true)


}


const subbedUsers = (id) => {
    const subs = reportData.filter((elem) => elem.id == id)
    return (
        subs.map((ellm) => {
            return (
                ellm.subscribers
            )
        })
    )
}

const subscribe = async (id) => {
    const response = await axios.post("/api/report/subscribe",{
        id: id,
        user: user
    })

    console.log(response)

    let value
    if (hasChanged === 0) {
        value = 1
    } else {
        value = 0
    }

    setHasChanged(value)
}


const unsubscribe = async (id) => {
    console.log("Fire")
    const response = await axios.post("/api/report/unsubscribe",{
        id: id,
        user: user
    })

    console.log(response)

    let value
    if (hasChanged === 0) {
        value = 1
    } else {
        value = 0
    }

    setHasChanged(value)
}


const createOne = async () => {
    const response = await axios.post("/api/report/create", {
        id: "FlAa"
    })

    console.log(response.data)
}

const userSub = (id) => {
    console.log("Fire")
    return (subscriptions.includes(id))
}


const log = () => {
    console.log(userSub("QswE"))

    console.log(subscriptions)
}


 
if (load) {


    return (
        <Box sx={{ marginLeft: "200px", borderRadius: "20px", overflowY: "auto", maxHeight: "80vh", padding: 2 }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, justifyContent: "center" }}>
                <Card variant="outlined" sx={{ width: "30vw", flex: "1 1 300px" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Predictive / Actual - Products & Variant Sales
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">
                            Type: Report
                        </Typography>
                        <Typography variant="body2">Time: 9:00am Mondays</Typography>
                        <Typography variant="body2">
                            Subscribers: {subbedUsers("QswE").join(", ")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {userSub("QswE") ? (
                            <Button onClick={() => unsubscribe("QswE")}>Unsubscribe</Button>
                        ) : (
                            <Button onClick={() => subscribe("QswE")}>Subscribe</Button>
                        )}
                    </CardActions>
                </Card>
    
                <Card variant="outlined" sx={{ width: "30vw", flex: "1 1 300px" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Freebies
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">
                            Type: Report
                        </Typography>
                        <Typography variant="body2">Time: 7:30am Everyday</Typography>
                        <Typography variant="body2">
                            Subscribers: {subbedUsers("FFgp").join(", ")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {userSub("FFgp") ? (
                            <Button onClick={() => unsubscribe("FFgp")}>Unsubscribe</Button>
                        ) : (
                            <Button onClick={() => subscribe("FFgp")}>Subscribe</Button>
                        )}
                    </CardActions>
                </Card>
    
                <Card variant="outlined" sx={{ width: "30vw", flex: "1 1 300px" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Missing Buttons / Variant Anomalies
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">
                            Type: Alert
                        </Typography>
                        <Typography variant="body2">Time: 10:00am & 5:00pm Everyday</Typography>
                        <Typography variant="body2">
                            Subscribers: {subbedUsers("BPjo").join(", ")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {userSub("BPjo") ? (
                            <Button onClick={() => unsubscribe("BPjo")}>Unsubscribe</Button>
                        ) : (
                            <Button onClick={() => subscribe("BPjo")}>Subscribe</Button>
                        )}
                    </CardActions>
                </Card>
    
                <Card variant="outlined" sx={{ width: "30vw", flex: "1 1 300px" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Job Failures
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">
                            Type: Report
                        </Typography>
                        <Typography variant="body2">Time: 7:30am Everyday</Typography>
                        <Typography variant="body2">
                            Subscribers: {subbedUsers("FlAa").join(", ")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {userSub("FlAa") ? (
                            <Button onClick={() => unsubscribe("FlAa")}>Unsubscribe</Button>
                        ) : (
                            <Button onClick={() => subscribe("FlAa")}>Subscribe</Button>
                        )}
                    </CardActions>
                </Card>
    
                <Card variant="outlined" sx={{ width: "30vw", flex: "1 1 300px" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Order Customer - Populate
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">
                            Type: Update
                        </Typography>
                        <Typography variant="body2">Time: 2:30am Everyday</Typography>
                    </CardContent>
                </Card>
    
                <Card variant="outlined" sx={{ width: "30vw", flex: "1 1 300px" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Order Line Items - Populate
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">
                            Type: Update
                        </Typography>
                        <Typography variant="body2">Time: 2:00am Everyday</Typography>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
} else {
    return (
        <div></div>
    )
}
}