import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';

import HelpCenterIcon from '@mui/icons-material/HelpCenter';






export default function PacasAI( { changeView, handleQuery } ) {

    const [textValue, setTextValue] = useState("")
    const [placeholderString, setPlaceholderString] = useState("")
    const [hasInteracted, setHasInteracted] = useState(false)


    const handleInputChange = (e) => {
        setTextValue(e.target.value)
    }


    const handleClick = () => {
            handleQuery(textValue)
            changeView(1)

    }


    const handleHelpClick = () => {
            changeView(2)
    }

    const charInt = 50
    const cycleDelay = 1500
    const emptyDelay = cycleDelay + 300


    const demoQuery = () => {


        const demoStrings = [
            "What are the top ten most returned products this year?",
            "What were the total sales from the past 3 months?",
            "Which product had the highest view to sale rate in June 2024?",
            
        ];
    
        const typeString = (demoString, delay) => {
            if (hasInteracted) {
                setPlaceholderString("")


            } else {
            const demoArray = demoString.split("");
            demoArray.forEach((elem, i) => {
                setTimeout(() => {
                    setPlaceholderString((prev) => prev + elem);
                }, charInt * i + delay);
            });
    
            // Clear the string after it's fully displayed with the intended delay
            setTimeout(() => {
                setPlaceholderString("");
            }, charInt * demoArray.length + delay + cycleDelay);
        }
        };
    
        const totalTime = demoStrings.reduce((acc, demoString) => {
            return acc + charInt * demoString.length + emptyDelay + charInt; // Include extra charInt
        }, 0);
    
        const cycleThroughStrings = () => {
            let delay = 0;
            demoStrings.forEach((demoString) => {
                if (!hasInteracted) {
                typeString(demoString, delay);
                delay += charInt * demoString.length + emptyDelay + charInt; // Add extra charInt
                }
            });
        };
    
        cycleThroughStrings(); // Start immediately
        setInterval(cycleThroughStrings, totalTime); // Restart after each full cycle
    };


    const handleInputClick = () => {
        setHasInteracted(true)
   
    }

    useEffect(() => {
        demoQuery()
    }, [])

    return (
        <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
        sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
        noValidate
        autoComplete="off"
        style={{
          backgroundColor: "black",
          borderRadius: "15px",
          marginRight: "3vw",
          marginBottom: "2vw",
          display: "flex",
          padding: "5px",
          alignItems: "center" // Align items to center vertically
        }}
      >
        <TextField 
          onClick={handleInputClick} 
          placeholder={placeholderString} 
          style={{
            backgroundColor: "white", 
            borderRadius: "15px", 
            width: "77vw",
            height: "56px" // Set a consistent height
          }} 
          value={textValue} 
          onChange={handleInputChange} 
          id="outlined-basic" 
          variant="outlined" 
        />
        <Button 
          style={{
            width: "5vw", 
            borderRadius: "10px", 
            marginLeft: "0vw",
            height: "56px" // Match TextField height
          }} 
          onClick={handleClick} 
          variant="contained" 
          type="submit"
        >
          Ask
        </Button>
      
        <HelpCenterIcon
          style={{
            width: "3vw", // Match button width if desired
            color: "white", 
            margin: "0px",
            height: "56px", // Match TextField height
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
            // padding: "0.5rem" // Adjust padding if needed
          }}
          onClick={handleHelpClick}
        />
      </Box>
      
      
    );
    
}